<template>
  <div class="info" style="margin-top: 0">
    <top-bar :title="'活动详情'" :left="true"></top-bar>
    <div class="config">
      <div class="top">
        <div class="title">{{dataForm.title}}</div>
        <div class="content-wrap">
          <div class="content">{{dataForm.content}}</div>
          <van-uploader v-model="newFileList" readonly :show-upload="false" :deletable="false"/>
          <div class="content-footer">
            <div class="footer-left">
              <van-icon name="underway-o" style="font-size: 16px" />
              {{dataForm.createTime}}
            </div>
            <!-- <div class="footer-right">30人已读</div> -->
          </div>
          <div class="detail-info">
            <div class="detail-title">活动须知</div>
            <div class="detail-item">
              <div class="item-icon">
                <van-image :src="require('@/assets/img/activeFlag.png')"></van-image>
              </div>
              <div class="apply" v-if="dataForm.needSign == 1">
                报名人数：
                <span class="content" style="margin-right: 4px">{{dataForm.signNum}}人</span>
                剩余：<span class="content">{{dataForm.joinNum - dataForm.signNum >= 0 ? dataForm.joinNum
                - dataForm.signNum : 0}}人</span>
              </div>
              <div class="apply" v-else>
                活动人数：
                <span class="content">{{dataForm.joinNum}}人</span>
              </div>
            </div>
            <div class="detail-item">
              <div class="item-icon">
                <van-image :src="require('@/assets/img/activeLocation.png')"></van-image>
              </div>
              <div class="apply">活动地点：<span class="content">{{dataForm.address}}</span></div>
            </div>
            <div class="detail-item">
              <div class="item-icon">
                <van-image :src="require('@/assets/img/activeTime.png')"></van-image>
              </div>
              <div class="apply">
                <div style="width: 78px;">活动时间：</div>
                <div class="content">{{dataForm.beginTime}} 至 {{dataForm.endTime}}</div>
              </div>
            </div>
            <div class="detail-item" v-if="dataForm.needSign == 1">
              <div class="item-icon">
                <van-image :src="require('@/assets/img/activeTime.png')"></van-image>
              </div>
              <div class="apply">报名截止时间：<span class="content">{{dataForm.signEndTime}}</span></div>
            </div>
          </div>
        </div>
      </div>

      <van-row class="btns" style="background: #fff">
        <van-col :span="24">
          <van-button v-if="!dataForm.sign && dataForm.canSign && applyShow" type="info" size="large" color="#387FF5" style="border-radius: 10px"
            @click="signSave">报名</van-button>
          <van-button v-if="dataForm.sign && unApplyShow" type="info" size="large" color="#387FF5" style="border-radius: 10px" plain
            @click="cancelSign">取消报名</van-button>
        </van-col>
      </van-row>
    </div>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import { getImageStream } from '@/utils/index'

export default {
  components: {
    topBar
  },
  data() {
    return {
      isEdit: 0,
      firstValue: [],
      secondValue: [],
      uploadPath: 'files/activity/temporary',
      changeOrDetail: false,
      beginDateShow: false,
      endDateShow: false,
      typeShow: false,
      isTasks: false,
      childrenVisible: false,
      surveyObjectShow: false,
      applyStartShow: false,
      applyEndShow: false,
      needSignShow: false,
      typeStr: '',
      surveyObjectStr: '居民',
      typeList: [],
      fileList: [],
      surveyObjectOptions: [{ value: '1', label: '居民' }, { value: '0', label: '社工/村委' }],
      options: [{ value: 1, label: '是' }, { value: 0, label: '否' }],
      newFileList: [],
      deleteFiles: [],
      checkedValue: [],
      childrenValue: [],
      needSign: '',
      dataForm: {
        id: '',//活动id
        title: '',  //活动名称
        address: '', //活动地址
        beginTime: '', //开始时间
        endTime: '', //结束时间
        joinNum: '', //参与人数
        typeOptions: [],//活动类型选择器下拉列表
        type: '', //活动类型
        content: '', //活动描述
        speaker: '',  //主讲人
        mobile: '', //联系电话
        fileList: [],
        surveyObject: '1',  //默认面向居民
        needSign: '',
        signBeginTime: '',
        signEndTime: '',
        putaway: 0
      },
      unApplyShow: false,
      applyShow: false
    }
  },
  methods: {
    getInfo() {
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.dataForm.id,
          orgId: this.$globalData.userInfo.orgId,
          orgUserId: this.$globalData.userInfo.userId
        })
      }).then(({ data }) => {
        console.log(data);
        if (data.code == 0) {
          if (data.entityVO.fileMessages && data.entityVO.fileMessages.length != 0) {
            data.entityVO.fileMessages.forEach(item => {
              let u;
              u = getImageStream(item.relativePath)
              let url = {
                uid: this.dataForm.fileList.length,
                id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                status: 'done',//uploading上传中，done上传完成，error上传失败
                fileType: item.fileType,
                relativePath: item.relativePath,
                url: u,
                realUrl: item.relativePath.replace(/\\/g, "/")
              }
              this.dataForm.fileList.push(url);
              this.newFileList.push(url);
            })
          }
          this.dataForm = data.entityVO
          this.needSign = data.entityVO.needSign == 1 ? '是' : '否'
          // let type = this.dataForm.type.split(',').map((n) => Number(n))
          // this.typeList.map(item => {
          //   type.map(val => {
          //     if (item.value == val) {
          //       this.checkedValue.push(item)
          //     }
          //     if (item.children !== null) {
          //       item.children.map(e => {
          //         if (e.value == val) {
          //           this.childrenValue.push(e)
          //         }
          //       })
          //     }
          //   })
          // })
          if (new Date() < new Date(this.dataForm.endTime.replace(/-/g, '/'))) {
            this.unApplyShow = true
          } else {
            this.unApplyShow = false
          }
          if (this.dataForm.needSign && new Date() > new Date(this.dataForm.signBeginTime.replace(/-/g, '/'))
            && new Date() < new Date(this.dataForm.signEndTime.replace(/-/g, '/')) && this.dataForm.joinNum - this.dataForm.signNum > 0) {
            this.applyShow = true
          } else {
            this.applyShow = false
          }
        }
        this.$toast.clear()
      }, err => { this.$toast.clear() })
    },
    signSave() {
      this.$toast.loading({
        duration: 0,
        message: '报名中...',
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/sign/signUp'),
        method: 'post',
        params: this.$http.adornParams({
          activityId: parseInt(this.dataForm.id),
          orgUserId: this.$globalData.userInfo.userId,
          communityId: this.$orgId
        })
      }).then(({ data }) => {
        this.$toast.clear()
        if (data && data.code === 0) {
          this.$toast.success({
            message: '报名成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                // this.isEdit = true;
                this.$toast.clear()
                this.getInfo()
              }, 1500)
            }
          })
        }
      })
    },
    cancelSign() {
      this.$dialog.confirm({
        message: '请确认是否取消报名',
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/activity/sign/delete'),
          method: 'post',
          params: this.$http.adornParams({
            activityId: parseInt(this.dataForm.id),
            userId: this.$globalData.userInfo.userId
          })
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '取消成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  // this.isEdit = true;
                  this.$toast.clear()
                  this.getInfo()
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      }).catch(() => {
        // console.log('不想取消了');
      });
    }
  },
  created() {
    this.dataForm.id = this.$route.query.id || this.$id
    this.getInfo()
  }
}
</script>
<style lang="scss" scoped>
.info {
  padding: 0;
  height: 100vh;
  background: #fff;
}
.typeCheck {
  display: flex;
  height: 150px;
  line-height: 115px;
  padding-left: 40px;

  span {
    display: block;
    flex: 1;
    font-size: 30px;
  }

  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }

  span:nth-child(3) {
    margin-right: -187px;
    font-size: 28px;
    color: #576b95;
  }
}

.typeCheckBox .van-cell__title {
  margin-left: 20px;
}

.typeCheckBox .van-icon {
  position: absolute;
  right: 40px;
  bottom: 45%;
}

.children {
  margin-left: 50px;
}

.children .van-cell__value {
  margin-left: 10px;
}

.config {
  border-radius: 10px;
  box-sizing: border-box;
  background: #FFFFFF;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top {
    .title {
      text-align: center;
      font-size: 32px;
      font-family: PingFang-Bold;
      font-weight: 500;
      color: #333333;
      padding: 24px 0;
    }

    .content-wrap {
      .content-title {
        height: 88px;
        font-size: 32px;
        font-family: PingFang-Bold;
        font-weight: 600;
        color: #333333;
        line-height: 88px;
      }

      .content {
        font-size: 28px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: #333333;
      }

      .content-footer {
        height: 88px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .footer-left {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .footer-right {
          font-size: 24px;
          font-family: PingFang-Bold;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }

  .footer {
    margin-bottom: 40px;
  }

  .detail-info {
    // width: 690px;
    // height: 464px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(229, 229, 229, 0.5);
    border-radius: 20px;
    padding: 0 20px;

    .detail-title {
      height: 88px;
      font-size: 32px;
      font-family: 'PingFang-Bold';
      font-weight: 600;
      color: #333333;
      line-height: 88px;
    }

    .detail-item {
      height: 88px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid #EDEDED;
      font-size: 24px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;

      &:nth-last-child(1) {
        border: none;
      }

      .apply {
        display: flex;
        align-items: center;


      }

      .check-btn {
        display: inline-block;
        width: 108px;
        height: 56px;
        background: #387FF5;
        border-radius: 8px;
        font-size: 24px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 56px;
        text-align: center;
      }

      .item-icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }

      .content {
        // margin-left: 10px;
        color: #373737;
      }
    }
  }
}
</style>
